import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'context/Auth';
import { getQueryParam, configAxios, makeRequestQuery, getUserInfo } from 'utils';
import { LoaderAuth } from '../styles';

export const BodySSO = () => {
  const [iframeLoaded, updateIframeLoaded] = useState(false);

  useEffect(() => {
    const handler = (event: { data: any }) => {
      if (typeof event.data === 'string' && event.data?.length) {
        processMessage(event.data);
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);

    // eslint-disable-next-line
  }, []); // empty array => run only once

  const processMessage = (eventData: string) => {
    if (!iframeLoaded) {
      updateIframeLoaded(true);
    }

    const data = JSON.parse(eventData);
    switch (data.name) {
      case 'login':
        if (!data.error) {
          auth.signin(() => {
            const authData = data?.message;
            const authHeader = `${authData.token_type} ${authData.access_token}`;

            configAxios({ authHeader });
            getUserInfo(auth.setUser);
            // @ts-ignore
            const { from } = location.state || { from: { pathname: '/' } };
            history.replace(from);
          });
        }
        break;
      case 'logout':
        if (!data.error) {
          auth.signout();
          window.location.href = '/auth/login';
        }
        break;
      case 'lang':
        if (!data.error) {
          auth.setLocale(data?.message);
        }
        break;
    }
  };

  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  return (
    <>
      {!iframeLoaded ? (<LoaderAuth />) : null}
      <iframe
        style={{
          zIndex: 1
        }}
        title="login"
        src={`${window._env_.SSO_URL}${buildQueryString()}`}
        frameBorder={0}
        height={'100%'}
        width={'100%'}
      />
    </>
  );
};

const buildQueryString = () => {

  const queryObject: Record<string, boolean | string> = {};

  if (getQueryParam('logout')) {
    queryObject.logout = true;
  }

  if (getQueryParam('resetPassword')) {
    queryObject.resetPassword = true;
  }

  return makeRequestQuery(queryObject);
};
