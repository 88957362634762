import axios from 'axios';
import apiConfig from '../config/api';
import { TypeUser } from '../context/Auth';

const API_MASTER_URL_USER_INFO = `${apiConfig.MASTER_PUBLIC}/users/info`;

export const getUserInfo = (onSuccess: (userData: TypeUser) => void) => {
  axios.get(API_MASTER_URL_USER_INFO).then(response => {
    if (response.data) {
      const userData = {
        name: response.data.props.name,
        email: response.data.props.email,
        role: response.data.role?.name,
        masquerade: response.data.masquerade
      };

      onSuccess(userData);
    } else {
      console.error(response);
    }
  }).catch(error => {
    console.error(error);
  });
};
