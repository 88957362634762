import React, { createContext, useContext, useEffect, useState } from 'react';
import { clearAuthUserData, getQueryParam, getUserInfo, preflightAPICall } from 'utils';
import { DEFAULT_LOCALE } from '../config/api';

export interface TDomain {
  props: {
    agency: string,
    tenant: string,
  },
  meta: {
    agency: string,
    tenant: string,
  },
}

interface IUserRole {
  id: string,
  name: string,
  group: {
    id: string,
    parent_id: string,
    name: string,
    title: string,
    domain_tag: string,
  },
  domain: TDomain,
}

export type TMasquerade = {
  all_roles?: IUserRole[],
  masquerade_user_id: string,
  masquerade_user_props: {
    name: string,
    email: string,
  },
};

export type TypeUser = {
  name: string,
  email: string,
  role?: string,
  masquerade: TMasquerade,
} | null;

export type TUserLocale = 'pl' | 'en';

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const AuthContext = createContext({
  user: null as TypeUser,
  isAuthenticated: -1,
  setUser: (data: TypeUser) => {},
  signin: (cb?: () => void) => {cb && cb();},
  signout: (cb?: () => void) => {cb && cb();},
  setLocale: (locale: string) => locale,
  locale: DEFAULT_LOCALE as TUserLocale,
});

export const ProvideAuth = ({ children }: {children: JSX.Element}) => {
  const auth = useProvideAuth();
  return (
    // @ts-ignore
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

const useProvideAuth = () => {
  useEffect(() => {

    if (getQueryParam('resetPassword') || getQueryParam('logout')) {
      clearAuthUserData();
    }

    const userString = sessionStorage.getItem('user');

    if (userString) {
      preflightAPICall(() => getUserInfo(setUser));
      setLoggedIn(1);
    } else {
      setLoggedIn(0);
    }

    //Check language
    if (localStorage.getItem('locale')) {
      updateLocale(localStorage.getItem('locale') as string);
    }

    // eslint-disable-next-line
  }, []);

  const [isAuthenticated, setLoggedIn] = useState(-1);
  const [locale, updateLocale] = useState(window._env_.DEFAULT_LOCALE);
  const [user, updateUser] = useState<TypeUser>(null);

  const setUser = (data: TypeUser) => {
    updateUser(data);
    sessionStorage.setItem('user', JSON.stringify(data));
  };

  const signin = (cb?: () => void) => {
    setLoggedIn(1);
    cb && cb();
  };

  const signout = (cb?: () => void) => {
    updateUser(null);
    setLoggedIn(0);
    clearAuthUserData();
    cb && cb();
  };

  const setLocale = (locale: string) => {
    localStorage.setItem('locale', locale);
    updateLocale(locale);
  };

  return {
    user,
    locale,
    signin,
    setUser,
    signout,
    setLocale,
    isAuthenticated,
  };
};
